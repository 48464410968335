<template>
  <el-card shadow="hover">
    <div slot="header">
      <span>场景解决方案表</span>
    </div>

    <el-row :gutter="20">
      <el-col :span="6"
        ><el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入解决方案相关信息进行筛选"
          clearable
      /></el-col>
      <el-button type="primary" @click="getSolution">查询方案</el-button>
      <el-button type="primary" style="float: right" @click="pushAddPage"
        >新增方案</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        @expand-change="expandChange"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="systemSolutionDetailsTableRef"
                :data="scope.row.systemSolutionDetails"
                v-loading="scope.row.loading"
                border
                :summary-method="getSystemDetialSummaries"
                :show-summary="true"
              >
                <el-table-column type="expand">
                  <template slot-scope="cscope">
                    <div class="expand_box">
                      <el-table
                        :data="cscope.row.systemSolutionDetailProducts"
                        border
                      >
                        <el-table-column
                          type="index"
                          width="80"
                          label="#"
                        ></el-table-column>
                        <el-table-column
                          prop="productName"
                          show-overflow-tooltip
                          label="商品名称"
                        >
                        </el-table-column>
                        <el-table-column label="商品品牌">
                          <template slot-scope="pscope">
                            {{
                              pscope.row.productBrandCNName +
                              '/' +
                              pscope.row.productBrandENName
                            }}
                          </template>
                        </el-table-column>

                        <el-table-column prop="amount" label="数量">
                        </el-table-column>
                        <el-table-column prop="price" label="价格">
                        </el-table-column>
                        <el-table-column prop="totalPrice" label="总价格">
                        </el-table-column>
                        <el-table-column label="型号规格">
                          <template slot-scope="pscope">
                            {{
                              pscope.row.productModel +
                              '/' +
                              pscope.row.productSpecs
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注">
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  type="index"
                  width="80"
                  label="#"
                ></el-table-column>
                <el-table-column
                  prop="systemName"
                  show-overflow-tooltip
                  label="系统名称"
                >
                </el-table-column>
                <el-table-column prop="subsystemName" label="子系统名称">
                </el-table-column>

                <el-table-column prop="productCount" label="产品个数">
                </el-table-column>
                <el-table-column prop="allPrice" label="总报价">
                </el-table-column>
                <el-table-column prop="remark" label="备注"> </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column label="方案编号">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              @click="handleCopy(scope.row.systemSolutionNumber, $event)"
              >{{ scope.row.systemSolutionNumber }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="systemSolutionName" label="解决方案名称">
        </el-table-column>
        <el-table-column prop="createTime" label="制表时间"> </el-table-column>
        <!-- <el-table-column prop="owinUserName" label="用户名"> </el-table-column> -->
        <el-table-column prop="createUserName" label="制表人">
        </el-table-column>
        <!-- <el-table-column prop="remark" label="备注"> </el-table-column> -->
        <el-table-column width="360" label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="downLoadSlnById(scope.row.id)"
              icon="el-icon-download"
              >下载简约方案</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="pushEdit(scope.row.id)"
              icon="el-icon-download"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteNowSln(scope.row.id)"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  getMySln,
  getMyInfoById,
  downLoadSln,
  deleteSln,
  downLoadSln2,
} from '@/api/solution'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 表格数据
      tableData: [],
    }
  },
  created() {
    this.getSolution()
  },
  methods: {
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    async getSolution() {
      let { data } = await getMySln(this.queryFilter)
      this.tableData = data.data.map((item) => {
        item.loading = false
        item.systemSolutionDetails = null
        return item
      })
      this.queryFilter.total = data.total
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getSolution()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getSolution()
    },
    // 前往新增页面
    pushAddPage() {
      this.$router.push({ path: '/Solution/addMySln' })
    },
    // 获取拼接之后的数据
    async expandChange(row, expandedRows) {
      if (!row.systemSolutionDetails) {
        row.loading = true
        try {
          let { data } = await getMyInfoById(row.id)

          this.tableData.forEach((item, index, arr) => {
            if (item.id == row.id) {
              arr[index].systemSolutionDetails = data.systemSolutionDetails
            }
          })

          row.loading = false
        } catch (error) {
          row.loading = false
        }
      }
    },
    // 获取系统详情统计
    getSystemDetialSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '统计'
          return
        }

        if (index === 4 || index === 5) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const values = data.map((item) => {
              return Number(item[column.property])
            })

            if (!values.every((value) => isNaN(value))) {
              let val = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)

              sums[index] = index == 4 ? val : val.toFixed(2)
              sums[index] += index == 4 ? '件' : '元'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['systemSolutionDetailsTableRef'].doLayout()
      })

      return sums
    },
    async downLoadSlnById(id) {
      await downLoadSln(id)
    },
    deleteNowSln(id) {
      this.$confirm('当前操作将会删除当前解决方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteSln(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getSolution()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    pushEdit(id) {
      this.$router.push({ path: '/Solution/myEdit/' + id })
    },
  },
}
</script>
<style lang="scss" scoped>
.expand_box {
  padding: 10px 20px;
  box-sizing: border-box;
}
</style>
